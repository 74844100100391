<template>
  <div class="DPopenWrap">
    <div class="DPopen content">
      <div class="topFun">
        <div class="fliterWrap">
          <el-date-picker v-model="filterDate" type="date" value-format="yyyy-MM-dd"></el-date-picker>
          <el-button type="primary" class="searchData" icon="el-icon-search" @click="getOpenInfor(1)"></el-button>
        </div>
        <div class="downLoad" @click="downPDFDocument">
          <div class="el-icon-download icon"></div>
          <div class="text">{{$t('oa.DocumentDownLoad')}}</div>
        </div>
      </div>
      <div class="FundInforWrap">
        <div class="portfolioExposureSummary table flex1">
          <h3>{{$t('oa.PortfolioExposureSummary')}}</h3>
          <table class="tableContent">
            <thead>
              <th class="Htitle left">{{$t('oa.Investment')}}</th>
              <th class="Htitle center">{{$t('oa.ProductType')}}</th>
              <th class="Htitle center">{{$t('oa.Quantity')}}</th>
              <th class="Htitle center">{{$t('oa.AvaOpeningPrice')}}</th>
              <th class="Htitle center">{{$t('oa.ClosingPrice')}}</th>
              <th class="Htitle center">{{$t('oa.PL')}}</th>
              <th class="Htitle center">{{$t('oa.InitialMarginReq')}}</th>
              <th class="Htitle center">{{$t('oa.MaintenanceMarginReq')}}</th>
              <th class="Htitle center">{{$t('oa.MaintenanceMarginRate')}}</th>
            </thead>
            <tbody>
              <tr v-for="(element,index) in tableListConfig.list" :key="index">
                <td class="Bcontent left">{{element.instrument}}</td>
                <td class="Bcontent center">{{element.productType}}</td>
                <td class="Bcontent center">{{element.quantity}}</td>
                <td class="Bcontent center">{{element.openingPrice}}</td>
                <td class="Bcontent center">{{element.closingPrice}}</td>
                <td class="Bcontent center">{{element.floatingPl}}</td>
                <td class="Bcontent center">{{element.initialMarginReq}}</td>
                <td class="Bcontent center">{{element.maintenanceMarginReq}}</td>
                <td class="Bcontent center">{{element.maintenaceMarginRate}}</td>
              </tr>
            </tbody>
          </table>
           <noData class="noData" :isShow="tableListConfig.totalCount == 0 || tableListConfig.totalCount == null" :text="$t(`oa.noData`)" />
        </div>
      </div>
      <div class="Pl" v-if="tableListConfig.totalCount > 0">{{$t('oa.Total')}} {{$t('oa.PL')}} : {{tableListConfig.pl}}</div>
      <el-pagination v-if="tableListConfig.totalCount > 0" class="pagination" background @current-change="handleCurrentChange" :current-page="tableListConfig.currentPage" :page-size="tableListConfig.pageSize" layout="total, prev, pager, next"
          :total="tableListConfig.totalCount">
        </el-pagination>
    </div>
  </div>
</template>
<script>
import url from "@/api/url.js";
import noData from "@/components/noData.vue";
export default {
  name: "",
  components: {
    noData,
  },
  data() {
    return {
      downLoading: false,
      filterDate: "",
      tableListConfig: {
        currentPage: 1,
        totalCount: -1,
        list: [],
        pageSize: 10,
        totalPage: 0,
        pl:0
      },
    };
  },
  created() {
    this.filterDate = this.setDate();
    this.initLang();
    this.getOpenInfor(1);
  },
  watch: {
    "$i18n.locale": function (newVal) {
      this.initLang();
    },
  },
  methods: {
    initLang() {},
    handleCurrentChange(val) {
     this.getOpenInfor(val);
    },
    getOpenInfor(page) {
      this.tableListConfig.currentPage = page;
      this.$axios
        .get(url.fundopendsummary, {
          params: {
            date: this.filterDate,
            limit: this.tableListConfig.pageSize,
            page: this.tableListConfig.currentPage,
          },
        })
        .then((res) => {
          this.tableListConfig.list = res.page.list;
          this.tableListConfig.totalCount = res.page.totalCount;
          this.tableListConfig.totalPage = res.page.totalPage;
          this.tableListConfig.pl = res.totalPL;
        });
    },
    downPDFDocument() {
      let token = localStorage.getItem('AUTtoken');
      if(this.tableListConfig.list.length<=0){
        this.$message.error(this.$t('oa.Nodatatodownload'));
        return;
      }
      window.open(`/oa/fund/fundopendsummary/download?date=${this.filterDate}&token=${token}`)
    },
  },
};
</script>
<style lang="scss" scoped>
.DPopen {
  width: 100%;
  padding: 20px;
  .Pl{
    font-size: 20px;
    margin: 30px;
    margin-left: 15px;
  }
  .left {
    text-align: left;
  }
  .center {
    text-align: center;
  }
  .flex1 {
    flex: 1;
  }
  .FundInforWrap {
    display: flex;
    flex-wrap: wrap;
    .TableRight,
    .TableLeft {
      width: 600px;
    }
    .table {
      min-width: 280px;
      margin: 0 1%;
      h3 {
        font-size: 20px;
        margin: 10px 0;
      }
      .tableContent {
        width: 100%;
        .width100 {
          width: 100px;
        }
        tr,
        th {
          height: 30px;
        }
        th {
          color: #305A9E;
        }
      }
    }
  }
}
</style>